import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import { login } from "../services/current-user.service";
import { getPathway, getStateTransitions } from "../services/flow.service";

import { calculateStatePos, calculateSuperStatePos } from "../utils/position.utils";

import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";

import StateNode from "./components/StateNode/StateNode";
import SuperStateNode from "./components/SuperStateNode/SuperStateNode";


import "reactflow/dist/style.css";

const edgeOptions = {
  animated: true,
  type: "step",
  style: {
    strokeWidth: 2,
    // stroke: 'black',
    // stroke: '#0D3EF0',
    // stroke: 'darkgray;'
    stroke: "#656769",
  },
  markerEnd: {
    width: 10,
    height: 10,
    // color: '#0D3EF0',
    color: "#656769",
    type: MarkerType.ArrowClosed,
  },
};

const nodeTypes = {
  state: StateNode,
  super_state: SuperStateNode,
}

function Flow(props, { hideAttribution = true }) {
  const { pathway_id } = useParams();
  const proOptions = { account: "paid-pro", hideAttribution };
  const { getIntersectingNodes, getNode } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);

  const generateId = () => {
    if (nodes.length === 0)
      return "state_1";
    return "state_" + String(
      parseInt(
        nodes.sort(function (a, b) {
          return parseInt(b["id"].split("_")[1]) - parseInt(a["id"].split("_")[1]);
        })[0]["id"].split("_")[1]
      ) + 1
    );
  };

  /* ------ State CRUD operations ------ */

  const onStateChange = (pathwayState) => {
    const state = pathwayState;
    setNodes(nodes.map((node) => {
      if (node.type !== "state" || node.data.id !== state.id) return node;

      return {
        ...node,
        data: {
          ...node.data,
        }
      }
    }))
  };

  const onStateDelete = (state_id) => {
    setNodes(nodes.filter((node) => node.data.id !== state_id));
  }

  const onStateAdd = (state, parent_id) => {
    const super_state = getNode(parent_id);
    const offset = super_state.data.stateNodeOffset;
    const index = nodes.filter((node) => node.parentNode === parent_id).length;
    setNodes(nodes.concat({
      id: generateId(),
      position: calculateStatePos(index, offset),
      data: { ...state },
      parentNode: parent_id,
      type: "state",
    }));
  }

  /* ------ Initialize flow data ------ */

  const initializeNodes = () => {
    getPathway(pathway_id)
      .then((pathway) => {
        const super_states = pathway.super_states;
        const super_state_nodes = [];
        const state_nodes = [];

        super_states.forEach((super_state, index) => {
          const super_state_node = {
            id: `super_state_${super_state.id}`,
            position: calculateSuperStatePos(index),
            data: { ...super_state, stateNodeOffset: index },
            draggable: false,
            deletable: false,
            type: "super_state",
          };

          super_state_nodes.push(super_state_node);

          super_state.states.forEach((state, i) => {
            const state_node = {
              id: `state_${state.id}`,
              position: calculateStatePos(i, index),
              data: { ...state },
              parentNode: `super_state_${super_state.id}`,
              type: "state",
            };

            state_nodes.push(state_node);
          });

        });

        const flow_nodes = [...super_state_nodes, ...state_nodes];
        setNodes(flow_nodes);
      });
  };

  const initializeEdges = () => {
    getStateTransitions(pathway_id)
      .then((states) => {
        const transition_edges = [];
        states.forEach((state) => {
          state.next_states.forEach((ns) => {
            transition_edges.push(
              {
                type: "step",
                data: { text: "0.15K" },
                source: `state_${state.id}`,
                target: `state_${ns.id}`,
                id: `${state.id}-${ns.id}`,
              }
            );
          });
        });
        console.log(transition_edges);
        setEdges([...transition_edges]);
      });
  }

  useEffect(() => {
    login("support@verto.ca", "Vertosupport123!")
      .then(() => {
        initializeNodes();
        initializeEdges();
      });

  }, []);

  /* ------ Add edge/node extra options/data ------ */

  useEffect(() => {
    setNodes((nodes) =>
      nodes.map((n) => {
        return {
          ...n,
          zIndex: n.type === "state" ? 2 : 0,
          data: {
            ...n.data,
            onStateChange: n.type === "state" ? onStateChange : null,
            onStateDelete: n.type === "state" ? onStateDelete : null,
            onStateAdd: n.type === "super_state" ? onStateAdd : null,
          },
        };
      })
    );
    setEdges((edges) =>
      edges.map((e) => {
        return {
          ...e,
          style: { strokeWidth: 2, stroke: "#656769" },
          markerEnd: { width: 10, height: 10, color: "#656769", type: "arrowclosed" },
          zIndex: 1,
        };
      })
    );
  }, [edges, setEdges, nodes, setNodes]);

  /* ------ React Flow control handlers ------ */

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      console.log(JSON.stringify(flow.edges));
    }
  }, [rfInstance]);

  /* ------ React Flow event handlers ------ */

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  const onNodeDrag = useCallback((_, node) => {
    const target = getIntersectingNodes(node).filter((node) => node.type === "super_state")[0].id;
    const hoverStyle = {
      boxShadow: "0px 0px 15px 1px #8DFF4A",
      transition: "box-shadow 0.5s",
    }

    setNodes((ns) =>
      ns.map((n) => {
        return {
          ...n,
          style: target === n.id ? hoverStyle : {},
        };
      })
    );
  }, [nodes]);

  const onNodeDragStop = useCallback((_, node) => {
    const target = getIntersectingNodes(node).filter((node) => node.type === "super_state")[0];
    const index = nodes.filter((node) => node.parentNode === target.id).length;
    const position = calculateStatePos(index, target.data.stateNodeOffset);

    const updatedNodes = nodes.map((nd) => {
      nd.style = {};
      if (nd.id !== node.id) return nd;
      return {
        ...nd,
        parentNode: target.id,
        position: position,
      }
    });

    setNodes([...updatedNodes]);

  }, [nodes]);

  return (
    <div style={{ height: "100%" }}>
      <ReactFlow
        deleteKeyCode={["Backspace", "Delete"]}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={edgeOptions}
        onInit={setRfInstance}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onConnect={onConnect}
        fitView
      >
        <div className="header react-flow__panel react-flow__controls">
          <button
            className="button react-flow__controls-button"
            onClick={onSave}
          >
            SAVE
          </button>
        </div>
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
}

export default Flow;
