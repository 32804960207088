import React from 'react';

// Prime React components
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import './StateForm.css';

export default function StateForm(props) {

  return (
    <Dialog
      className="state-modal"
      header={props.header}
      visible={props.visible}
      onHide={() => props.setVisible(false)}
    >
      <div className="state-modal-body">
        <label htmlFor="name">State Name</label>
        <InputText value={props.state?.name || ''} onChange={(e) => props.setState({ ...props.state, name: e.target.value })} id="name" />
        <div className="state-modal-btn-group">
          <Button label="Save" onClick={props.saveState} />
          {props.deleteState ? <Button label="Delete" onClick={props.deleteState} /> : <></>}
        </div>
      </div>
    </Dialog>
  )
}
