import { memo, useCallback } from "react";
import { Handle, Position } from "reactflow";

import "./StateNode.css";

export default memo(({ data }) => {
  const onClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: "state-click",
        payload: data
      },
      "*"
    );
  }, [data]);

  return (
    <div onClick={onClick}>
      <div
        className={
          "journey-state" +
          (data.isAlternative ? " journey-state-alt" : "") +
          (data.isFinal ? " journey-state-final" : "") +
          (data.current ? " journey-state-current" : "") +
          (data.selected ? " journey-state-active" : "")
        }
      >
        <Handle type="target" position={Position.Left} />
        {data.name}
        <Handle
          type="source"
          position={Position.Right}
        />
      </div>
    </div>
  );
});
