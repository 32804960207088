import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import "./App.css";
import Flow from "./Flow/Flow";
import Journey from "./Journey/Journey";
import Login from "./Flow/components/Login/Login";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login></Login>
        </Route>
        <Route path="/pathways/:pathway_id">
          <div className="editor">
            <ReactFlowProvider>
              <Flow copd={true} />
            </ReactFlowProvider>
          </div>
        </Route>
        <Route path="/visualize">
          <div className="editor">
            <ReactFlowProvider>
              <Journey />
            </ReactFlowProvider>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
