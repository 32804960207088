import React, { useCallback, useEffect, useMemo, useState } from "react";

import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  useEdgesState,
  useNodesState
} from "reactflow";

import "./Journey.css";

import * as positionHelpers from "../utils/position.utils";
import StateNode from "./components/StateNode/StateNode";
import SuperStateNode from "./components/SuperStateNode/SuperStateNode";

const calculateStatePos = (index, alt = false) => {
  let { x, y } = positionHelpers.calculateStatePos(index, alt ? 0.5 : 0);
  y -= 30;
  return { x, y };
};

const calculateSuperStatePos = (index) => {
  const { x, y } = positionHelpers.calculateSuperStatePos(index);
  return { x, y };
};

const edgeOptions = {
  animated: true,
  type: "step",
  style: {
    strokeWidth: 2,
    stroke: "#686869",
  },
  markerEnd: {
    width: 10,
    height: 10,
    color: "#686869",
    type: MarkerType.ArrowClosed,
  }
};

const selectedEdgeOptions = {
  ...edgeOptions,
  style: {
    ...edgeOptions.style,
    stroke: "#0d3ef0",
    strokeWidth: 3,

  },
  animated: false,
  markerEnd: {
    ...edgeOptions.markerEnd,
    color: "#0d3ef0",
  }
};


function Journey() {
  const proOptions = { account: "paid-pro", hideAttribution: true };
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);

  const nodeTypes = useMemo(() => ({
    state: StateNode,
    super_state: SuperStateNode,
  }), []);

  const onMessage = useCallback((event) => {
    if (event.data.type !== 'journey-map') return;
    const { journey, pathway, interactive } = event.data.payload;

    const journeyStates = journey.reduce((acc, curr) => {
      acc.push(curr.source, curr.target)
      return acc;
    }, []);


    const superStates = [];
    const states = [];
    const stateTransitions = [];

    pathway.superStates.forEach((superState, index) => {
      const hasAlternatives = superState.states.length > 1 && index > 0;
      const showAlternatives = interactive && index > 0;

      superStates.push({
        id: `super_state_${superState.id}`,
        position: calculateSuperStatePos(index),
        data: { ...superState, hasAlternatives, showAlternatives },
        draggable: false,
        type: "super_state",
      });

      superState.states
        .filter(state => (journeyStates.includes(state.id) || showAlternatives))
        .forEach((state, i) => {
          const isFinal = index === pathway.superStates.length - 1 && i === 0;
          const current = index === pathway.superStates.length - 2 && i === 0;

          const state_node = {
            id: `state_${state.id}`,
            position: calculateStatePos(i),
            data: { ...state, isFinal, current, isAlternative: !journeyStates.includes(state.id), superState },
            parentNode: `super_state_${superState.id}`,
            draggable: false,
            type: "state",
          };

          states.push(state_node);

          if (interactive) {

            if (index > 0) {
              const previousState = pathway.superStates[index - 1].states[0];
              const transition = {
                type: "step",
                source: `state_${previousState.id}`,
                target: state_node.id,
                id: `${previousState.id}-${state_node.id}`,
              }

              if (!(journeyStates.includes(previousState.id) && journeyStates.includes(state.id))) {
                stateTransitions.push(transition);
              }
            }

            if (state.selected && index < pathway.superStates.length - 1) {
              const nextState = pathway.superStates[index + 1].states.find(s => s.selected)

              const transition = {
                type: "step",
                ...selectedEdgeOptions,
                source: `state_${state.id}`,
                target: `state_${nextState.id}`,
                id: `${state.id}-${nextState.id}`,
              }

              if (!(journeyStates.includes(state.id) && journeyStates.includes(nextState.id))) {
                stateTransitions.push(transition);
              }
            }
          }
        });

      if (hasAlternatives && !showAlternatives) {
        const altNode = {
          id: `alternative_${superState.id}`,
          position: calculateStatePos(1, true),
          data: { ...superState, isAlternative: true, name: `+${superState.states.length - 1} others` },
          parentNode: `super_state_${superState.id}`,
          draggable: false,
          type: "state",
        };

        const previousState = superStates[superStates.length - 2].data.states.find(state => journeyStates.includes(state.id));

        const transition = {
          source: `state_${previousState.id}`,
          target: altNode.id,
          type: "step",
          id: `${previousState.id}-${altNode.id}`,
        };

        states.push(altNode);
        stateTransitions.push(transition);
      }
    });

    journey?.forEach((t) => {
      const st = pathway.superStates.find(s => s.states.find(state => state.id === t.target));
      const selectedTarget = st.states.find(state => state.id === t.target).selected;

      const ss = pathway.superStates.find(s => s.states.find(state => state.id === t.source));
      const selectedSource = ss.states.find(state => state.id === t.source).selected;

      stateTransitions.push(
        {
          type: "step",
          ...(selectedTarget && selectedSource ? selectedEdgeOptions : edgeOptions),
          source: `state_${t.source}`,
          target: `state_${t.target}`,
          id: `${t.source}-${t.target}`,
        }
      );
    });

    setNodes([...superStates, ...states]);
    setEdges(stateTransitions);
  }, []);


  // iFrame listener
  useEffect(() => {
    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, [onMessage]);

  // iFrame ready
  useEffect(() => {
    window.parent.postMessage({ type: "journey-map-ready" }, "*");
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <ReactFlow
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={edgeOptions}
        onInit={setRfInstance}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}

export default Journey;
