import axios from "axios";

const flowUrl = "http://localhost:3000";

export const login = (username, password) => {
  return axios.post(`${flowUrl}/oauth/token`, {
    grant_type: 'password',
    username,
    password,
  }).then((response) => {
    let token = response.data.access_token;
    if (!!token) {
      localStorage.setItem('t', token);
    } else {
      logout();
    }

  }).catch((error) => {
    console.log(error);
  })
}

export const logout = () => {
  localStorage.removeItem('t');
  localStorage.removeItem('access_token');
  sessionStorage.removeItem('access_token');
}