import { useState } from "react";
import { Handle, Position, useNodeId } from "reactflow";

// Prime React components
import { Button } from "primereact/button";

import StateForm from "../StateForm/StateForm";

import "./SuperStateNode.css";

function SuperStateNode({ data }) {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({});
  const nodeId = useNodeId();

  const addState = () => {
    data.onStateAdd(state, nodeId);
  }

  return (
    <div>
      <StateForm
        visible={visible}
        setVisible={setVisible}
        state={state}
        setState={setState}
        saveState={addState}
      />
      <div className="pathway-node">
        <div className="pathway-header">
          <div>
            <label
              className="pathway-node-label"
              htmlFor="text"
            >
              {data.name}
            </label>
          </div>
          <div>
            <Button className="add-state-btn" icon="pi pi-plus" onClick={() => setVisible(true)} />
          </div>
        </div>
        <hr></hr>
        <div className="">
          <Handle className="handle" type="target" position={Position.Left} />
          <Handle className="handle" type="source" position={Position.Right} />
        </div>
      </div>
    </div>
  );
}

export default SuperStateNode;
