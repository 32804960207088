import axios from "axios";

const flowUrl = "http://localhost:3000";

// TODO: interceptors for headers

export const getPathway = (pathway_id) => {
  return axios
    .get(`${flowUrl}/api/v1/pathways/${pathway_id}?include=SuperState`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('t')}`
      }
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export const getStateTransitions = (pathway_id) => {
  return axios
    .get(`${flowUrl}/api/v1/pathways/${pathway_id}/state_transitions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('t')}`
      }
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
