import { memo, useState } from "react";
import { Handle, Position } from "reactflow";

import StateForm from "../StateForm/StateForm";

import "./StateNode.css";

export default memo(({ data }) => {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState(data);

  const saveState = () => {
    data.onStateChange(state);
  }

  const deleteState = () => {
    data.onStateDelete(state.state_id);
  }

  return (
    <div>
      <StateForm
        state={state}
        setState={setState}
        visible={visible}
        setVisible={setVisible}
        saveState={saveState}
        deleteState={deleteState}
        header={data.label}
      />
      <div
        className={`state-node ${data.color === "green" ? "loading" : ""}`}
        onClick={() => setVisible(true)}
      >
        <Handle className="handle" type="target" position={Position.Left} />
        <div className="state-node-content">
          <label htmlFor="text">
            {data.name}
          </label>
        </div>
        <Handle
          className="handle"
          type="source"
          position={Position.Right}
          id="a"
        />
      </div>
    </div>
  );
});
