const margin = 100;

const super_state_header_height = 45;
const super_state_spacing = 100;
const super_state_width = 300;

const vertical_state_spacing = 25;
const state_width = 250;
const state_height = 50;



export const calculateSuperStatePos = (i) => {
  let x = margin + (i + 1) * super_state_spacing + i * super_state_width;
  let y = margin;
  return { x: x, y: y };
};

export const calculateStatePos = (i, offset) => {
  let state_pos = {
    x: (super_state_width - state_width) / 2,
    y: super_state_header_height + 60 + offset * 30,
  };
  state_pos.y += i * (state_height + vertical_state_spacing);
  return state_pos;
};
