
import { Button } from "primereact/button";

import "./SuperStateNode.css";

function SuperStateNode({ data }) {

  const states = data.states.length;
  const showAlternatives = data.showAlternatives;
  const hasAlternatives = data.hasAlternatives;
  const style = {};

  if (showAlternatives) {
    style.minHeight = `${50 + (states) * 80}px`;
  }

  return (
    <div>
      <div className={'super-state ' + (hasAlternatives ? 'super-state-alt' : '')} style={style}>
        <div className="pathway-header">
          <div>
            <label
              className="super-state-label"
              htmlFor="text"
            >
              {data.name}
            </label>
          </div>
        </div>
        {
          data.hasAlternatives &&
          <div className="super-state-alternatives">
            Alternatives
          </div>
        }
      </div>
    </div>
  );
}

export default SuperStateNode;
